const data = [
  {
    id: "videos",
    icon: "iconsminds-video-tripod",
    label: "Videos",
    to: `/app/videos`,
    access: ["Admin", "Manager"],
  },
  {
    id: "users",
    icon: "iconsminds-conference",
    label: "Users",
    to: `/app/users`,
    access: ["Admin"],
  },
];
export default data;
