import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import ListingTable from "components/ListingTable";
import moment from "moment";

const UsersList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [count, setCount] = useState(1);
  const [token, setToken] = useToken();

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`/users?page=${currentPage}&per_page=10`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          const { users, count, page, pageCount } = result.data;
          setData(users);
          setCount(count);
          setCurrentPage(page);
          setTotalPage(pageCount);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (currentPage, selectedPageSize, word) => {
    ///
    axios
      .get(
        `/users?search=${word}&page=${currentPage}&per_page=${selectedPageSize}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const { users, count, page, pageCount } = result.data;
        setData(users);
        setCount(count);
        setCurrentPage(page);
        setTotalPage(pageCount);
        setLoading(false);
      });
  };

  const cols = React.useMemo(
    () => [
      {
        Header: "#",
        // accessor: "title",
        cellClass: "list-item-heading",
      },
      {
        Header: "First Name",
        accessor: "firstName",
        cellClass: "list-item-heading w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        cellClass: "list-item-heading w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Username",
        accessor: "username",
        cellClass: "text-muted w-20",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Telegram ID",
        accessor: "telegramId",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Language",
        accessor: "language",
        cellClass: "text-muted w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        cellClass: "text-muted w-25",
        Cell: (props) => (
          <>{moment(props.value).add(5, "hours").format("DD/MM/YYYY HH:mm")}</>
        ),
      },
    ],
    []
  );

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Users List</h1>
              <div className="text-zero top-right-button-container"></div>
            </div>
          </Colxx>
        </Row>
        <Separator className="mb-5" />
      </div>
      <ListingTable
        columns={cols}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleSearch={handleSearch}
        totalPage={totalPage}
        totalItemCount={count}
        loading={loading}
      />
    </AppLayout>
  );
};

export default UsersList;
